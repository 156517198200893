import initialCrudState from "../../templates/crud/state";

const initialModel = () => ({
  id: null,
  host: null,
  note: null,
  locales: [],
  googleTrackingId: null,
  googleViewId: null,
  siteplanLink: null,
  siteplanLinkEditable: null,
  isSyncing: false,
  isSynced: false,
  tags: [],
  isSearchable: true,
  protocol: null
});

const isAnnotationDialogOpen = false;
const annotationDialogId = null;

export default () => ({
  ...initialCrudState(initialModel),
  isAnnotationDialogOpen: isAnnotationDialogOpen,
  annotationDialogId: annotationDialogId,
  tag: {},
  website: {},
  locale: {}
});
