import tableState from "../../../templates/table/state";
import { createEntityPlaceholders } from "@/store/helpers";

export default {
  ...tableState(),
  ...createEntityPlaceholders([
    "lead",
    "offer",
    "country",
    "form",
    "website",
    "language",
    "subject",
    "company",
    "submission"
  ])
};
