import api from "@/api";
import { FETCH as DISPATCH_FETCH } from "@/store/templates/table/action-types";
import tableActions from "@/store/templates/table/actions";

const fetchCall = api.website.article.fetchAll;

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    // OVERRIDE THE DEFAULT
    const websiteId = context.rootState.website.model.id;
    return await tableActions.fetch(context, {
      fetchCall: fetchCall.bind(null, websiteId)
    });
  }
};
