import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import articlesModule from "./articles";
import annotationsModule from "./annotations";
import analyticsMetricsModule from "./analyticsMetrics";
import submissionsModule from "./submissions";
import siblingsModule from "./siblings";

export default {
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations,
  modules: {
    articles: articlesModule(),
    annotations: annotationsModule(),
    analyticsMetrics: analyticsMetricsModule(),
    submissions: submissionsModule(),
    siblings: siblingsModule()
  }
};
