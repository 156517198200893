import tableState from "../../../templates/table/state";

export default () => {
  const customizedTableState = tableState();
  customizedTableState.pagination.sortBy = "date";
  customizedTableState.pagination.descending = true;

  return {
    ...customizedTableState,
    annotationType: {},
    annotationSubType: {},
    file: {}
  };
};
