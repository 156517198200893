import crudGetters from "../../templates/crud/getters";

export default {
  website: state => state.model,
  id: state => state.model.id,
  host: state => state.model.host,
  note: state => state.model.note,
  locales: state => state.model.locales,
  googleTrackingId: state => state.model.googleTrackingId,
  googleViewId: state => state.model.googleViewId,
  siteplanLink: state => state.model.siteplanLink,
  siteplanLinkEditable: state => state.model.siteplanLinkEditable,
  isAnnotationDialogOpen: state => state.isAnnotationDialogOpen,
  annotationDialogId: state => state.annotationDialogId,
  timeframeMetric: state => state.timeframeMetric[state.model.timeframeMetric],
  tags: state => state.model.tags,
  isSearchable: state => state.model.isSearchable,
  protocol: state => state.model.protocol,
  siblings: state =>
    state.model.siblings.map(sibling => state.website[sibling]),
  sibling: state => id => state.website[id],
  ...crudGetters
};
