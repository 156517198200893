import tableMutations from "../../../templates/table/mutations";
export default {
  setArticleArticleRemark(state, { remark, articleSiblingId }) {
    state.articleSibling[articleSiblingId].remark = remark;
  },
  setArticleArticleColor(state, { color, articleSiblingId }) {
    state.articleSibling[articleSiblingId].color = color;
  },
  ...tableMutations
};
