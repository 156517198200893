import tableGetters from "../../../templates/table/getters";

export default {
  // eslint-disable-next-line no-unused-vars
  siblingHeaders: (state, getters, rootState) => {
    let websites = rootState.website.model.siblings.map(
      sibling => rootState.website.website[sibling]
    );
    websites.push(rootState.website.model);

    let localedWebsites = [];

    websites.forEach(website =>
      localedWebsites.push({
        id: website.id,
        text: website.host,
        locales: website.locales.map(
          locale => rootState.website.locale[locale].code
        ),
        sortable: false
      })
    );

    let sortedWebsites = [];
    const orders = ["nl_be", "nl_nl", "fr_be", "fr_fr", "en_gb", "de_de"];

    orders.forEach(order => {
      localedWebsites.forEach(website => {
        if (website.locales.includes(order)) {
          sortedWebsites.push(website);
        }
      });
    });

    return sortedWebsites;
  },
  siblingForWebsite: state => (clusterId, websiteId) => {
    const siblings = state.articleCluster[clusterId].articleSiblings.map(
      sibling => state.articleSibling[sibling]
    );

    return siblings
      .filter(sibling => sibling.websiteId === websiteId)
      .find(Boolean);
  },
  siblingArticle: state => siblingId =>
    state.article[state.articleSibling[siblingId]?.article],
  articleSiblingRemark: state => siblingId =>
    state.articleSibling[siblingId].remark,
  articleSiblingColor: state => siblingId =>
    state.articleSibling[siblingId].color,
  ...tableGetters
};
