import crudActions from "@/store/templates/crud/actions";
import api from "@/api";
import {
  SET_IS_LOADING,
  SET_MODEL
} from "@/store/templates/crud/mutation-types";

export default {
  ...crudActions(api.website, "website"),
  async addTags({ commit }, { websiteId, tags }) {
    commit(SET_IS_LOADING, true);
    const { entities, result } = await api.website.addTags(websiteId, tags);
    commit(SET_MODEL, { entities, id: result });
    commit(SET_IS_LOADING, false);
    return result;
  }
};
