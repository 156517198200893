import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";
import siblingsOnlyChildrenModule from "@/store/modules/website/siblings/only-children";

export default () => ({
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations,
  modules: {
    onlyChildren: siblingsOnlyChildrenModule()
  }
});
