import crudMutations from "../../templates/crud/mutations";

export default {
  setHost(state, host) {
    state.model.host = host;
  },
  setNote(state, note) {
    state.model.note = note;
  },
  setLocales(state, locales) {
    state.model.locales = locales;
  },
  setGoogleTrackingId(state, googleTrackingId) {
    state.model.googleTrackingId = googleTrackingId;
  },
  setGoogleViewId(state, googleViewId) {
    state.model.googleViewId = googleViewId;
  },
  openAnnotationDialog(state, isOpen) {
    state.isAnnotationDialogOpen = isOpen;
  },
  setAnnotationDialogId(state, id) {
    state.annotationDialogId = id;
  },
  setSiteplanLink(state, siteplanLink) {
    state.model.siteplanLink = siteplanLink;
  },
  setSiteplanLinkEditable(state, siteplanLinkEditable) {
    state.model.siteplanLinkEditable = siteplanLinkEditable;
  },
  setTags(state, tags) {
    state.model.tags = tags;
  },
  addTags(state, tags) {
    state.model.tags.push(tags);
  },
  setIsSearchable(state, isSearchable) {
    state.model.isSearchable = isSearchable;
  },
  setProtocol(state, protocol) {
    state.model.protocol = protocol;
  },
  ...crudMutations
};
