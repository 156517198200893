import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
import initialState from "./state";

export default () => ({
  namespaced: true,
  state: () => initialState(),
  getters,
  actions,
  mutations
});
